.trainers__wrapper {
  margin: 60px -20px;
  display: flex;
  flex-wrap: wrap;
  position: relative;

  @include sm {
    margin-top: 20px;
  }

  @include md {
    margin-top: 20px;
  }
}
