.search-trainer {
  display: flex;
  align-items: center;

  @include sm {
    flex-direction: column;
  }

  @include md {
    flex-direction: column;
  }

  &__search-area {
    width: 100%;
    z-index: 2;
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: 0;
    padding: 10px;
    border-radius: 10px;
    background: #fff;
    filter: drop-shadow(0px 2px 8px rgba(0, 0, 0, 0.13));

    @include md {
      width: 100%;
      margin: 0 0 10px 0;
    }

    @include sm {
      width: 100%;
      margin: 0 0 10px 0;
    }

    &-wrapper {
      z-index: 2;
    }
  }

  &__location-row {
    display: flex;
    align-items: center;
  }

  &__sort-row {
    display: flex;
    flex: 1;
    max-width: 350px;
    margin: 0 -10px;

    .col-lg-6 {
      padding: 0 10px;
    }
  }

  &__search-input {
    background-color: white;
    width: 100%;
    height: 100%;
    border: none;

    @include sm {
      border-right: none;
    }

    @include md {
      border-right: none;
    }

    &::placeholder {
      font-weight: 0.9rem;
      opacity: 0.5;
    }
  }
}
