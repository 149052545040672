.top-promotion {
  padding-top: 40px;
  margin-top: 30px;
  margin-bottom: 150px;
  position: relative;

  .typography.title {
    margin-top: 40px;
    max-width: 400px;
    text-align: left;
    text-transform: uppercase;
    text-align: left !important;
    line-height: 65px;
    font-size: 45px;

    &::after {
      left: 0 !important;
      transform: translateX(0);
    }
  }

  .background-element {
    position: absolute;
    width: 100%;
    height: 950px;
    background-repeat: no-repeat;
    top: -15%;
    background-size: 1024px 950px;
  }
}
