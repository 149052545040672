.long-user-card {
  width: 100%;
  height: 100%;
  border-radius: 40px;
  background-color: white;
  box-shadow: 2px 10px 30px rgba(0, 178, 169, 0.14);
  margin-bottom: 20px;
  position: relative;

  &__img-wrapper {
    position: relative;
    overflow: hidden;
  }

  &__img {
    border-top-left-radius: 40px;
    border-top-right-radius: 40px;
    width: 100%;
    height: 200px;
    object-fit: cover;
  }

  &__fee {
    font-weight: normal;
    font-size: 0.9rem;
    color: var(--dark);
    border-left: var(--gray3);
  }

  &__navigator-wrapper {
    position: absolute;
    bottom: -45px;
    right: 18px;
    border-radius: 25.5px;
    background-color: rgba(237, 227, 199, 0.8);
    box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.16);
    font-weight: 600;
    font-size: 0.9rem;
    cursor: pointer;
    color: black;
    opacity: 0;
    transition: all 0.4s;
  }

  &__profile-navigator {
    padding: 12px 45px;
  }

  &__detail {
    flex: 1;
    font-size: 0.9rem;
    display: flex;
    color: var(--gray10);
    max-height: 120px;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 4;
    -webkit-box-orient: vertical;
  }

  &:hover {
    .long-user-card__navigator-wrapper {
      opacity: 1;
      bottom: 15px;
    }
  }

  &__body {
    display: flex;
    flex-direction: column;
    padding: 15px 25px;
  }

  &__location-wrapper {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 5px;
    margin-top: 15px;
    border-top: 1px solid;
    border-color: var(--gray9);
  }

  &__location-text {
    color: var(--grey1);
    flex: 1;
    font-size: 0.9rem;
    display: flex;

    svg {
      margin-right: 5px;
      fill: var(--grey1);
    }
  }

  &__classification {
    position: absolute;
    bottom: -16px;
    left: 50%;
    transform: translateX(-50%);
    width: 35px;
    height: 35px;
    background: var(--blue);
    border: 1px solid #fff;
    border-radius: 50%;
    box-shadow: 6px 6px 30px rgba(50, 176, 157, 0.12);
    display: flex;
    align-items: center;
    justify-content: center;
    color: white;
    text-transform: uppercase;
    font-weight: bold;
  }
}

.scale-t {
  transform: scale(1.03);
  -webkit-box-shadow: 0px 0px 13px 2px rgba(122, 122, 122, 0.83);
  box-shadow: 0px 0px 13px 2px rgba(122, 122, 122, 0.83);
}
