.trainer-card {
  width: 100%;
  border-radius: 40px;
  overflow: hidden;
  background-color: white;
  box-shadow: 6px 20px 30px rgba(0, 178, 169, 0.12);
  margin-bottom: 20px;

  &__img-wrapper {
    position: relative;
  }

  &__img {
    border-top-right-radius: 40px;
    border-top-right-radius: 40px;
    width: 100%;
    height: 200px;
    object-fit: cover;
  }

  &__fee {
    font-weight: bold;
    font-size: 0.9rem;
    color: var(--dark);
    border-left: var(--gray3);
    padding-left: 20px;
  }

  &__profile-navigator {
    position: absolute;
    bottom: 15px;
    right: 18px;
    padding: 12px 45px;
    border-radius: 25.5px;
    background-color: rgba(237, 227, 199, 0.8);
    box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.16);
    font-weight: 600;
    font-size: 0.9rem;
    cursor: pointer;
    color: black;
  }

  &__body {
    display: flex;
    flex-direction: column;
    padding: 15px 25px;
  }

  &__location-wrapper {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 5px;
    margin-top: 15px;
    border-top: 1px solid;
    border-color: var(--gray9);
  }

  &__location-text {
    color: var(--grey1);
    flex: 1;
    font-size: 0.9rem;

    svg {
      margin-right: 5px;
      fill: var(--grey1);
    }
  }
}
