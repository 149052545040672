.dietitan-card_wrapper {
  display: flex;
  flex-direction: column;
  padding: 10px;
  border-radius: 15px;
  background: #fff;
  box-shadow: 2px 3px 18px rgba(0, 0, 0, 0.09);
  height: 77%;
  justify-content: space-around;
  width: 50%;

  @include sm {
    width: 100%;
  }

  figcaption {
    font-weight: 500;
    color: #404041;
  }

  &__suspended {
    border: 1px solid #f01c62;
    box-shadow: 2px 3px 18px rgba(0, 0, 0, 0.09);
  }
}

.dietitan-price {
  &__saveButton {
    margin-left: auto;
    @include sm {
      width: 90%;
    }
  }
}
