.upload-icon {
  margin-bottom: 15px;
}

.file {
  padding: 35px 130px;
  border: 2px dashed var(--blue);
  border-radius: 4px;
  display: flex;
  flex-direction: column;
  align-items: center;

  &:focus {
    outline: none;
  }
}

.file-upload {
  &__text-wrapper {
    margin: 0 30px;

    @include sm {
      margin: 0;
    }
  }

  &__file-name {
    text-overflow: ellipsis;
    display: block;
    overflow: hidden;

    @include sm {
      max-width: 170px;
    }

    @include lg {
      max-width: 320px;
    }
  }

  &__section {
    background-color: white;
    width: 100%;
    border-radius: 20px;
    padding: 25px 35px 80px;
    box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.13);
    margin-top: 30px;

    .file {
      @include sm {
        .blue {
          min-width: 100%;
        }
      }
    }

    @include sm {
      padding: 15px;
    }

    .file-path {
      font-size: 0.8rem;
      color: var(--black2);
    }

    &__or {
      font-size: 0.9rem;
      font-weight: 500;
      color: var(--gray3);
    }

    .blue {
      min-width: 200px;
      padding: 10px 15px;
    }
  }

  &__next-button {
    width: 100%;
    margin-top: 30px;
  }

  &__next-link {
    text-decoration: underline;
    color: var(--gray1);
    font-size: 1rem;
    text-align: center;
    font-weight: 500;
    margin-top: 15px;
  }

  .progress {
    margin: 10px 0;
  }

  .progress-bar {
    background-color: var(--blue);
  }

  &__info-row {
    display: flex;
    align-items: center;
    margin: 10px 0 5px;
    font-size: 0.9rem;
    color: var(--black1);
    padding: 0 5px;

    svg {
      width: 20px;
      height: 20px;
      margin-right: 10px;
    }
  }

  &__trash-icon {
    margin-left: auto;
    cursor: pointer;
  }
}

.step-six {
  &__criminal-record {
    display: flex;
    font-size: 0.8rem;
    text-align: left;
    color: var(--red);
    font-weight: 500;
    margin-top: 30px;

    svg {
      margin: 0 10px 3px 0;
      fill: #f01c62;
    }
  }

  &__criminal-record-link {
    display: block;
    text-decoration: underline;
    font-size: 14px;
    line-height: 17px;
    text-align: center;
    color: var(--black2);
    cursor: pointer;
    font-weight: 500;
    margin-top: 30px;
  }
}

.health-report {
  font-size: 0.8rem;
  text-align: left;
  color: var(--red);
  font-weight: 500;
  margin-top: 30px;
}

.step-one-wrapper {
  .underline-text {
    text-decoration: underline;
    color: var(--blue);
    margin-right: 5px;
  }

  &__checkbox-wrapper {
    margin: 30px 0 15px;
  }
}

.google-map-modal {
  height: 50vh;
  width: 80vh;
  padding: 20px;
}

.soft-text {
  font-size: 15px;
  font-weight: 300;
  color: #000000;
}

.prof-register-modal {
  width: 600px;
  padding: 35px 150px;
  display: flex;
  flex-direction: column;
  align-items: center;

  @media (max-width: 720px) {
    width: auto;
    padding: 0 !important;
    text-align: center;
  }

  &__phone {
    font-size: 1rem;
    font-weight: bold;
    color: var(--black1);
    text-align: center;
  }

  &__error {
    font-size: 0.9rem;
    color: var(--red);
    margin-top: 15px;
  }

  button.blue {
    width: 300px;
  }
}

.centered-div {
  display: flex;
  justify-content: center;
  align-items: center;
}

.step-four-wrapper {
  @media (min-width: 1280px) {
    min-width: 400px;
  }
}

.adress-apartment {
  width: 100%;
  margin-left: 10px;
}

.adress-no {
  width: 100%;
  margin-right: 10px;
}

.no-margin {
  margin: 0 !important;
}

.branch-modal-content {
  background-color: #fefefee0;
  margin: auto;
  padding: 20px;
  border: 1px solid #888;
  width: 100%;
}

/* The Close Button */

.close {
  color: #aaaaaa;
  float: right;
  font-size: 28px;
  font-weight: bold;
}

.close:hover,
.close:focus {
  color: #000;
  text-decoration: none;
  cursor: pointer;
}

.branch-button {
  color: #707070 !important;
  border-radius: 25px !important;
  background-color: #ffffff !important;
  border: 1px solid #00b2a9 !important;
  min-height: 45px;
  padding: 10px;
  outline: none;
  min-width: 135px;
  margin-bottom: 10px;
  margin-right: 5px;
  width: auto !important;

  @media (max-width: 720px) {
    min-width: 90px;
  }
}

.activeButton {
  background-color: #00b2a9 !important;
  color: #ffffff !important;
}

.buttonWrapper {
  width: 100%;
  display: flex;
  justify-content: space-between;
  margin-top: 10px;
}

.map-dialog-wrapper {
  width: 100vh;
  height: 73vh;
}

.branchWrapper {
  display: flex;
  padding: 10px;
  flex-wrap: wrap;

  &::after {
    content: '';
    flex: auto;
  }
}

.infoText {
  font-size: 10px;
}
