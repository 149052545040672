.banner-image {
   display: none;
  object-fit: cover;
  width: 100%;
  height: 85px;
}
.tab-wrapper {
  min-height: 70vh;
  position: relative;
  top: -25px;
  background: white;
  border-top-left-radius: 25px;
  border-top-right-radius: 25px;

  .tab-content {
    margin-bottom: 200px;
  }
  .nav-tabs {
    margin-top: 30px;
    margin-bottom: 0px;
    border: 0;

    .nav-link {
      border: none;
      line-height: 18px;
      text-align: left;
      color: #181818;
    }
  }

  .nav-link.active {
    border: 0;
    border-top-left-radius: 25px;
    border-top-right-radius: 25px;
    :focus {
      outline: none;
      box-shadow: none;
    }
  }
}

.tab-wrapper__shadow {
  margin-top: 5vh;
  box-shadow: 0px 8px 20px rgba(0, 0, 0, 0.16);
  margin-bottom: 10vh;
}

.about-wrapper {
  .MuiInput-underline:after {
    display: none;
  }

  .MuiInput-underline:before {
    display: none;
  }
}

.about-hr {
  margin-left: 0;
  width: 87%;
}

.saveButton {
  cursor: pointer;
  margin-left: 2%;
  font-weight: 600;
  font-size: 15px;
  letter-spacing: 0.01em;
  color: var(--blue);
}

.disable {
  cursor: not-allowed;
  opacity: 0.8;
  color: var(--gray2);
}
