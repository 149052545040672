.blog-cart__right {
  @media (max-width: 400px) {
    font-size:2vmin;
  }
}

.blog-cart {
  display: flex;
  flex-direction: row;
  width: 100%;
  height: 425px;
  overflow: hidden;
  @media (max-width: 768px) {
    flex-direction: column;
    height:650px;
    margin: 0 -10px;
  }

&__left {
  width: 100%;
  height: 100%;
}
  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    object-position: center;

    @media (max-width: 1200px) {
      width: 100%;
      margin: 20px;
      height: 100%;
    }
  }

  &__right {
    font-size: 2vmin;
    width: 100%;
    height: 100%;
    padding-left: 36px;
    padding-right: 46px;
    padding-top: 46px;
  }

  &__title {
    font-size: 30px;
    font-weight: 700;
    line-height: 36px;
  }

  &__detail {
    font-size: 18px;
    margin-bottom: 30px;
    margin-top: 30px;
    line-height: 22px;
    color: var(--gray10);
    max-height: 120px;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 4;
    -webkit-box-orient: vertical;
  }

  &__writer {
    color: black;
  }

  .bl-btn {
    margin-left: 16px;
    margin-top: 20px;
    text-transform: uppercase;
  }
}