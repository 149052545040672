
    @media (max-width: 768px) {
        .MuiTypography-h6 {
            font-size: .8rem!important;
        }

        .MuiTypography-h5 {
            font-size: 1.2rem!important;
        }

        .MuiTypography-h4 {
            font-size: 1.4rem!important;
        }

        .MuiTypography-h3 {
            font-size: 1.7rem!important;
        }

        .typography.text, .MuiTypography-body1 {
            font-size: 10pt!important;
        }
    }
