.component-inputs {
    width: 100%;
    
    .label {
        font-weight: 700;
        font-size: 12pt;
    }

    input.form-control {
        width: 100%;
        height: 50px;
        border-radius: 0;
        border: none;
    }
}