.facility-card-wrapper {
  width: 285px;
  height: auto;
  min-height: 100px;
  border-radius: 15px;
  background: #fff;
  box-shadow: 2px 3px 18px rgba($color: #000000, $alpha: 0.12);
  padding: 10px;
  margin-right: 20px !important;
  margin-bottom: 30px !important;
}

.not-accepted-card {
  border: 1px solid #dd3964;
  box-shadow: 2px 3px 18px rgba(0, 0, 0, 0.3);
  margin-right: 20px !important;
}

.accepted-text {
  font-weight: 600;
  font-size: 13px;
  letter-spacing: 0.01em;
  text-align: left;
  color: #00b2a9;
}

.waiting-accept-text {
  font-weight: 600;
  font-size: 13px;
  letter-spacing: 0.01em;
  text-align: left;
  color: #f01c62;
  opacity: 0.36;
}

.card-wrapper {
  display: flex;
  flex-wrap: wrap;
}
