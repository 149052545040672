.basic-info {
  margin-top: 130px;

  .next-img {
    position: absolute;
    right: 50px;
    top: 100%;
    display: flex;
    flex-wrap: nowrap;
    z-index: 100;
    height: calc(100% + 50px);

    .image {
      height: auto;

      &.i1 {
        height: auto;
        margin-right: -100px;
      }

      img {
        height: auto;
      }
    }
  }

  @media (max-width: 1200px) {
    margin-top: 110px;
  }

  .starter {
    width: 100%;
    position: relative;

    .container {
      position: relative;
    }

    img {
      width: 100%;
      height: auto;
    }
  }

  .content {
    padding-top: 15px;
    padding-bottom: 120px;
    padding-left: 50px;
    padding-right: 50px;


    position: relative;
    
    @media (max-width: 1200px) {
      .row {
        padding-left: 15px;
        padding-right: 15px;
      }
    }
  }
  .message-content {
    padding-top: 15px;
    padding-bottom: 120px;
    position: relative;

    @media (max-width: 768px) {
      margin: 0;
      padding: 0;
      width: auto;
      background-color: red;
    }
  }
  .base-icon {
    width: 100%;
    height: auto;
    display: flex;
    justify-content: center;
    align-items: flex-start;
    margin-top: 30px;
    margin-bottom: 30px;
    flex-wrap: wrap;

    li {
      display: flex;
      justify-content: center;
      flex-direction: column;
      align-items: center;

      span {
        display: flex;
        padding: 15px;
        text-align: center;
        font-size: 10pt;
      }
    }
  }

  .animation-info-group {
    width: 100%;
    margin-top: 45px;
    margin-bottom: 45px;

    ul {
      width: 100%;
      height: auto;
      display: flex;
      flex-wrap: wrap;
      justify-content: center;
      margin-top: 30px;
      margin-bottom: 15px;

      li {
        width: 100%;
        display: flex;
        justify-content: center;
        opacity: 0.4;
        height: 45px;
        align-items: center;
        cursor: pointer;
        transition: all 0.3s cubic-bezier(1, 0.98, 0, -0.01) 0s;
        line-height: 125%;

        &.active,
        &:hover {
          font-size: 1.7em;
          color: #00b2a9;
          opacity: 1;
        }

        @media (max-width: 1200px) {
          height: auto;
          padding: 7px;

          &.active,
          &:hover {
            font-size: 1.2em;
            line-height: 120%;
          }
        }
      }

      &.animation-img {
        flex-wrap: nowrap;
        margin-top: 45px;
        margin-top: 45px;
        width: calc(100% + 14px);

        @media (max-width: 1200px) {
          width: 100%;
          flex-wrap: wrap;
          margin-top: 15px;
          margin-top: 15px;
        }

        li {
          display: flex;
          height: 210px;
          opacity: 1;
          margin: 7px;
          position: relative;

          .img {
            position: absolute;
            left: 0;
            right: 0;
            width: 100%;
            height: 100%;
            background-size: cover;
            overflow: hidden;
            border-radius: 30px;
            background-position: center center;
            transition: all 0.3s cubic-bezier(1, 0.98, 0, -0.01) 0s;
          }

          &.active,
          &:hover {
            .img {
              width: calc(100% + 15px);
              height: calc(100% + 15px);
              z-index: 100;
              left: -7.5px;
            }
          }

          @media (max-width: 1200px) {
            width: 100%;
            height: 180px;
            max-width: 50%;
            margin: 7px 0;

            .img {
              position: relative;
            }

            &.active,
            &:hover {
              .img {
                position: relative;
                width: 100%;
                height: 100%;
                z-index: 100;
                left: 0;
              }
            }
          }
        }
      }
    }
  }
}
