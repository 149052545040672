@import './reset/index.css';

// Some of them are same on purpose
:root {
  --z-index-dropdown: 2;
  --base-font-size: 16px;
  --blue: #00b2a9;
  --blue2: #8cdeda;
  --blue3: #d6f7f5;
  --black1: #1a1818;
  --black2: #181818;
  --black3: #383838;
  --black4: #6e6d6d;
  --dark: #1a1818;
  --softDark: #181818;
  --gray1: #909090;
  --gray2: #bbbbbb;
  --gray3: #666666;
  --gray4: #d3d3d3;
  --gray5: #2c2c2c;
  --gray6: #7b7b7b;
  --gray7: #e8f0f8;
  --gray8: #f2f2f2;
  --gray9: #c4c4c4;
  --gray10: #707070;
  --white1: #f8f8f8;
  --trunge: #ffba00;
  --orange2: #f77e0b;
  --red: #f01c62;
  --white: #ffff;
  --white2: #c5c4c4;
  --white3: #f7f8fa;
}

html {
  font-size: var(--base-font-size);
}

body {
  font-family: 'Poppins', sans-serif;
  overflow-y: overlay;
}
.scroll-to-top {  
  cursor: pointer;
  width: 0;
  height: 0;
  border: 18px solid transparent;
  border-top: 0;
  border-bottom: 34px solid #00b2a9;
  position: fixed;
  bottom: 40px;
  right: 70px;
  color: #fff;
  z-index: 1;
}
.scroll-to-top .icon {
  margin-top: 10px;
  animation: scrollTop 0.5s alternate ease infinite;
}
.arrowtext{
  font-size: 10px;
    display: table;
    position: absolute;
    top: 35px;
    right: -27px;
    color: white;
    background: #00b2a9;
    border-radius: 5px;
    width: 50px;
}
@keyframes scrollTop {
  from {
      transform: translateY(2px);
  }
  to {
      transform: translateY(-1px);
  }
}
#root {
  width: 100%;
  height: auto;
  overflow: hidden;
}

.MuiPopover-root {
  z-index: 100000 !important;
}



.MuiPickersToolbar-toolbar,
.MuiPickersDay-daySelected {
  background-color: var(--blue) !important;
}

.MuiPickersDatePickerRoot-toolbar,
.MuiPaper-root {
  border-radius: 30px !important;
}

#pt-point-page {
  position: relative;
  right: 0;
  transition: right 0.5s ease;

  @media (max-width: 1200px) {
    &.open-hamburger-menu {
      right: -55%;
    }

    .over-flow-y-auto {
      width: calc(100% + 45px);
      margin: 0 -30px;
      padding: 0 30px;
      overflow-y: auto;
      margin-bottom: 15px;
    }
  }

  @media (max-width: 768px) {
    &.open-hamburger-menu {
      right: -70%;
    }
  }
}

.MuiPopover-paper {
  margin-top: 40px !important;
}

.cp {
  width: 16px;
  margin-bottom: 3px;
  cursor: pointer;
}

#ETBIS {
  margin-top: 25px;

  @media (max-width: 720px) {
    margin-right: auto;
    margin-left: auto;
  }
}

.main {
  min-height: 100vh;

  @media (max-width: 1200px) {
    min-height: inherit;
  }
}

.app-root {
  padding-top: 130px;
  width: 100%;
  height: auto;
  overflow: hidden;

  @media (max-width: 1200px) {
    padding-top: 80px;
  }
}

/* layout */
@import './layout/grid';

/* pages */
@import './pages/index.scss';

/* header */
@import './header';

/* buttons */
@import './buttons';

/* sliders */
@import './sliders';

/* typography */
@import './typography';

/* material */
@import './material';

/* banner */
@import './banner';

/* inputs */
@import './inputs';

/* step-bar */
@import './step-bar';

/* tab */
@import './tab';

/* footer */
@import './footer';

/* dialog */
@import './dialog';

/* certificate */
@import './certificate/';

/* googleMap */
@import './googleMap/';

/* locationCard */
@import './locationCard/';

/* user-profile */
@import './user-profile/';

/* components */
@import './components/index.scss';

@import './sub-page';

@import './facilityCard';

@import './DietitianPriceCard';

@import './proficiencyCard';

@import './responsive';

@import './message';

@import './contact';

@import './comment';

/* bootstrap */
@import './bootstrap';
