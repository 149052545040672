.proficiencyCard {
  &__wrapper {
    padding: 10px;
    min-height: 190px;
    border-radius: 15px;
    background: #fff;
    border: 1px solid #dd3964;
    box-shadow: 2px 3px 18px rgba(0, 0, 0, 0.09);
    width: 50%;
    margin-right: 10px;
    margin-bottom: 10px;

    @include sm {
      width: 100%;
    }
  }

  &__line__wrapper {
    height: 120px;
    flex-direction: column;
    justify-content: space-between;
    overflow-y: scroll;
  }

  &__line {
    border-bottom: 0.5px solid #afafaf;
  }
}

.dietitianProficiency {
  &__wrapper {
    display: flex;
    flex-wrap: nowrap;
    justify-content: center;
  }
}
