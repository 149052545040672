.modal {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: auto;

  .modal-content {
    padding: 15px 30px;
    border-radius: 20px;
    background: var(--white1);
    box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.13);
    max-width: 100%;
  }

  .modal-dialog {
    @include sm {
      width: 100%;
    }

    max-width: 90%;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%) !important;
  }

  .close-icon {
    svg {
      cursor: pointer;
      width: 15px;
      height: 15px;
      position: absolute;
      right: 25px;
      top: 20px;
      z-index: 15;
    }
  }

  .modal-footer {
    border-top: 1px solid var(--gray1);
  }
}

@media (min-width: 1200px) {
  .container {
    max-width: 100% !important;
  }
}
