.gm-style-iw-c {
  border: 2px solid #46b2a9;
}

.regular-search-box {
  input {
    border: none !important;
    width: 100% !important;
    background: white !important;
  }
}

.map-wrapper {
  height: 45vh;

  margin: 20px 0px 30px 0px;
  .text-container {
    margin: 10px 0px 10px 0px;

    width: 100%;
    display: flex;
    justify-content: flex-end;
  }
  .map-scale {
    color: #46b2a9;
    cursor: pointer;
  }

  @media (max-width: 768px) {
    width: 100% !important;
  }

  .gm-ui-hover-effect {
    display: none !important;
  }
}

.medium-map-wrapper {
  height: 35vh;

  @media (max-width: 768px) {
    width: 100% !important;
  }

  .gm-ui-hover-effect {
    display: none !important;
  }
}

.marginTop {
  margin-top: 2%;
}

.search-box {
  position: absolute !important;
  z-index: 10;
  width: 85%;
  left: 50%;
  top: 10%;
  transform: translate(-50%, -50%);
  height: 40px !important;
  border-radius: 7px;
  background: #fff;
  box-shadow: 0px 3px 10px rgba(0, 0, 0, 0.17);

  @media (max-width: 768px) {
    width: 40vh !important;
  }
  input {
    border: none !important;
    width: 100% !important;
    background: white !important;
  }
}
