// .blog-top {
//   &__banner {
//     height: 800px;
//     background: no-repeat url('../../assets/blog/blog-top-long.jpeg');
//     object-fit: cover;
//   }
// }

.blog-detail {
  &__text {
    width: 1050px;
    text-align: justify;

    @media (max-width: 1250px) {
      width: 750px;
    }
    @media (max-width: 769px) {
      width: 500px;
    }
    @media (max-width: 576px) {
      width: 250px;
    }
  }

  &__img {
    margin-top: 40px;
    margin-bottom: 60px;
    max-width: 1280px;
    max-height: 625px;
    overflow: hidden;
    display: flex;
    align-items: center;

    img {
      object-fit: cover;
      width: 100%;
      height: 100%;
      object-position: center;
    }
  }

  &__share-buttons {
    margin-bottom: 10px;
    display: flex;
    justify-content: center;
    @media (max-width: 576px) {
      width: 250px;
    }
    button {
      margin-right: 10px;
      width: 32px;
      height: 32px;
    }
  }

  &__share-title {
    margin-right: 10px;
    font-size: 16px;
    font-weight: bold;
    path {
      width: 50px;
    }
  }

  &__wrapper {
    .typography.title {
      margin-bottom: 28px;

      &.line::after {
        margin-top: 32px;
      }
    }
  }

  .blog-cart-list {
    margin-bottom: 270px;
  }
}
