.packet-slider {
  height: auto;
  position: relative;
  width: 100%;

  .slick-slider-buttons {
    width: 95%;
    position: relative;
    height: 1px;
    z-index: 1000;

    .slick-button {
      position: absolute;
      padding: 0 15px;
      color: #fff;
      font-size: 2.5em;
      top: -38px;
      cursor: pointer;

      @media (max-width: 768px) {
        // display: none;
      }

      &.next {
        right: 15px;
        background: #ccc;
        transform: matrix(1, 0, -0.3, 1, 0, 0);
      }

      &.prev {
        right: 88px;
        background: #ccc;
        transform: matrix(1, 0, -0.3, 1, 0, 0);
      }

      &:hover {
        top: -23px;
        background: rgb(0, 178, 169);
        font-size: 3.5em;
        transform: matrix(1, 0, -0.3, 1, 0, 0);
      }
    }
  }

  .sliders {
    margin-top: 45px;
    height: 610px;

    @media (max-width: 768px) {
      height: auto;
    }

    .slick-slider {
      height: 610px;

      @media (max-width: 768px) {
        width: calc(100% + 250px);
        height: auto;
      }
    }
  }

  .slider-item {
    padding: 15px;
    cursor: pointer;
    height: 610px;
    perspective: 1000px;

    @media (max-width: 768px) {
      height: auto;
    }

    .slider-item-content {
      width: 100%;
      height: 580px;
      box-shadow: rgba(0, 0, 0, 0.12) 5px 5px 7px 5px;
      border-radius: 30px;
      position: relative;
      transition: transform 1s;
      transform-style: preserve-3d;
      transition-timing-function: ease-in-out;

      @media (max-width: 768px) {
        height: 450px;
        padding: 15px;
      }

      &:hover {
        top: -12px;
        box-shadow: rgba(0, 178, 169, 0.12) 5px 5px 7px 5px;
        transform: rotateY(180deg);
      }

      .front-card,
      .back-card {
        position: absolute;
        height: 100%;
        width: 100%;
        top: 0;
        left: 0;
        -webkit-backface-visibility: hidden;
        backface-visibility: hidden;
        transform: rotateX(0deg);
        @media (max-width: 768px) {
          height: 580px;
        }
        &__title {
          color: #00b2a9;
          font-size: 28px;
          font-weight: 600;
          text-align: center;
          @media (max-width: 768px) {
            font-size: 18px;
          }
        }
        &__detail {
          text-align: center;
          font-size: 22px;
          font-weight: 400;
          display: -webkit-box;
          -webkit-line-clamp: 14;
          -webkit-box-orient: vertical;
          overflow: hidden;
          text-overflow: ellipsis;

          @media (max-width: 768px) {
            font-size: 14px;
          }
        }
      }

      .back-card {
        height: 580px;
        background-color: white;
        padding: 20px;
        transform: rotateY(180deg);
        z-index: 2;
        border-radius: 30px;
        text-align: center;
        @media (max-width: 768px) {
          height: auto;
        }
      }

      .img-item {
        width: 100%;
        display: flex;
        padding-left: 10px;
        padding-top: 30px;
        padding-right: 25px;
        padding-bottom: 10px;

        @media (max-width: 768px) {
          flex-wrap: wrap;
          padding: 10px;
        }

        .img {
          width: 100%;
          height: 420px;
          border-radius: 30px;
          background-size: cover;
          background-position: center center;
          flex: 1 1 100%;
          position: relative;
          display: flex;
          align-items: center;
          justify-content: center;

          @media (max-width: 768px) {
            height: 280px;
            flex-wrap: wrap;
          }

          @media (max-width: 425px) {
            height: 210px;
            flex-wrap: wrap;
          }

          .clock-times {
            position: absolute;
            z-index: 100;
            top: 12px;
            right: 12px;
            display: flex;
            flex-direction: row-reverse;

            span.icon {
              font-size: 1.4em;
              color: #fff;
              width: 40px;
              height: 40px;
              border-radius: 40px;
              display: flex;
              align-items: center;
              justify-content: center;
              background: rgba(40, 40, 40, 0.7);
              position: relative;
              z-index: 2;
              transform: rotate(0deg);
              @media (max-width: 576px) {
                display: none;
              }
            }

            span.text {
              width: 120px;
              font-size: 10pt;
              height: 40px;
              display: flex;
              align-items: center;
              justify-content: center;
              padding-right: 50px;
              padding-left: 13px;
              background: rgba(40, 40, 40, 0.7);
              color: #fff;
              margin-right: -39px;
              position: relative;
              z-index: 1;
              border-radius: 40px;
              overflow: hidden;
              @media (max-width: 576px) {
                display: none;
              }
            }

            // &:hover,
            // &.active {
            //   span.text {
            //     // display: flex;
            //     // width: 140px;
            //     // padding-right: 50px;
            //   }

            //   span.icon {
            //     transform: rotate(360deg);
            //   }
            // }
          }

          .points {
            display: flex;
            position: absolute;
            top: 0px;
            left: 0px;
            padding: 15px;
            border-bottom-right-radius: 40px;
            background: radial-gradient(
              rgb(255, 255, 255),
              rgba(255, 255, 255, 0.53)
            );
            opacity: 0.7;

            @media (max-width: 1200px) {
              padding: 10px;
            }

            li {
              margin: 2px;
              cursor: pointer;

              svg {
                color: #ccc;
                font-size: 9pt;

                @media (max-width: 1200px) {
                  font-size: 5pt;
                }
              }

              &:hover,
              &.active {
                svg {
                  color: #ffba00;
                }
              }
            }
          }
        }

        .team {
          position: absolute;
          color: #fff;
          background: #00b2a9;
          width: 50px;
          height: 50px;
          display: flex;
          align-items: center;
          justify-content: center;
          border-radius: 50px;
          bottom: -25px;
          border: 2px solid #fff;
          font-size: 1.3em;
          font-weight: bold;
        }

        .info {
          writing-mode: vertical-rl;
          text-orientation: mixed;
          justify-content: flex-start;
          align-items: flex-start;
          margin-left: 5px;
          margin-right: -17px;
          margin-top: 15px;
          margin-bottom: 15px;

          @media (max-width: 768px) {
            writing-mode: inherit;
            text-orientation: inherit;
            width: 100%;
            margin: 0;
            padding: 0;
            font-size: 10pt;
            text-align: center;
            margin-top: 30px;
          }

          p {
            justify-content: flex-start;
            align-items: flex-start;
            font-size: 1.2em;
            letter-spacing: 10px;
            max-height: 345px;
            overflow: hidden;
            flex-direction: row;
            flex-wrap: nowrap;
            max-width: 30px;
            white-space: nowrap;
            @media (max-width: 1200px) {
              max-width: inherit;
              max-height: inherit;
              white-space: inherit;
            }

            @media (max-width: 768px) {
              width: 100%;
              justify-self: center;
              align-items: center;
            }
          }
        }
      }

      .text-item {
        width: 100%;
        padding: 15px 30px;
        display: flex;
        flex-wrap: wrap;
        flex-direction: row;

        .typography.title {
          justify-content: flex-start;
          text-align: left;
          align-items: flex-start;
        }

        .info {
          width: 100%;

          ul {
            width: 100%;

            li {
              display: list-item;
            }
          }

          span {
            font-size: 1.1em;
            font-weight: 900;
            position: relative;
            left: 30px;
          }
        }

        @media (max-width: 768px) {
          padding: 0;
          text-align: center;

          .typography.title {
            justify-content: center;
            text-align: center;
            align-items: center;
            font-size: 10pt;
          }

          .info {
            width: 100%;
            margin: 0;
            padding: 0;

            ul {
              width: 100%;
              padding: 7px;

              li {
                display: list-item;
                text-align: center;
                color: red;
              }
            }

            .col,
            .col-auto {
              width: 100%;
              text-align: center;
              padding: 0;
              margin: 0;
            }

            span {
              left: 0;
            }
          }
        }
      }
    }
  }

  .slick-active.slick-current {
    .slider-item {
      .slider-item-content {
        top: -12px;
        box-shadow: rgba(0, 178, 169, 0.12) 5px 5px 7px 5px;
      }
    }
  }

  .slick-track {
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .slick-slide {
    width: 500px !important;
  }

  .packet-category {
    width: 100%;
    position: relative;

    ul {
      display: flex;
      justify-content: center;
      align-items: center;
      margin-left: -15px;
      margin-right: -15px;

      li {
        width: auto;
        display: flex;
        margin: 15px;
        cursor: pointer;

        a {
          display: flex;
          padding: 15px;
          color: #303030;
          font-weight: 700;
          font-size: 14pt;
          border-bottom: 2px solid #fff;
        }

        &:hover,
        &.active {
          a {
            color: #00b2a9;
            border-bottom: 2px solid #00b2a9;
          }
        }
      }
    }
  }
}

.card-front,
.card-back {
  position: absolute;
  width: 100%;
  height: 100%;
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
}
