.slider-focus {
  
  height: 330px;
  position: relative;
  margin-top: 50px;
  margin-bottom: 120px;


  .button{
    margin-top: -110px;
    font-size: 20px;
    border-radius: 10px;
    width:50%;
    
    @media (max-width: 700px) {
     margin-top: -110px;
     font-size: 100% !important;
    }
  }

  @media (max-width: 1200px) {
    height: auto;
  }

  .slick-slider-buttons {
    width: 100%;
    position: relative;
    height: 1px;
    z-index: 1000;

    @media (max-width: 1200px) {
      // display: none;
    }

    .slick-button {
      position: absolute;
      padding: 0 15px;
      background: #00b2a9;
      color: #fff;
      font-size: 2.5em;
      top: 166px;
      cursor: pointer;
      @media (max-width: 768px) {
        top:14px
        
      }
      &.next {
        right: 9px;
        transform: matrix(1, 0, -0.3, 1, 0, 0);
      }

      &.prev {
        right: -60px;
        transform: matrix(1, 0, -0.3, 1, 0, 0);
        @media (max-width: 768px) {
          right: 53px;
          top:-256px;
        }
      }
    }
  }

  & > .container{
    width: 100%;
  height: 100%;
  object-fit: cover;
  object-position: center;
  & > .container > .row {
    height: 565px;

    @media (max-width: 1200px) {
      height: auto;
    }
  }

 
  }
  .slider-item-content {
    width: 100%;
    height: 565px;
    display: flex;
    align-content: center;
    flex-direction: row;
    flex-wrap: wrap;
    margin-left: 5px;
    margin-right: 5px;
    padding-left: 40px;


    @media (max-width: 1200px) {
      height: auto;
      margin-top: 120px;
      margin-bottom: 30px;
      height: 270px;
      margin-bottom: 90px;
    }
    @media (max-width: 768px) {
      margin: 116px 25px 0 0;
      height: auto;
    }
    ul {
      margin-left: -4px;
      margin-right: -4px;
      gap:7px;
      li {
        .btn {
          font-size: 9pt !important;
          font-weight: 500 !important;
          border: 1px solid #000;
          margin: 0 4px;
          padding: 3px 15px;
          border-radius: 15px;
        }

        &:hover {
          .btn {
            background: #000;
            color: #fff;
          }
        }
      }
    }
  }

  .right {
    height: 565px;

    @media (max-width: 768px) {
      height: 270px;
    }

    .item-right {
      position: absolute;
      width: calc(50% + 990px);
      height: calc(565px + 70px);

      @media (max-width: 1200px) {
        height: calc(565px + 70px);
        width: calc(50% + 768px);
      }

      @media (max-width: 768px) {
        width: calc(100% + 240px);
        height: 300px;
      }

      @media (max-width: 565px) {
        width: calc(100% + 370px);
      }

      @media (max-width: 370px) {
        width: calc(100% + 470px);
      }

      .slick-slider {
        .slick-arrow {
          display: none !important;
        }
      }

      .slider-item {
        width: 100%;
        height: calc(565px + 70px);
        padding: 10px;
        position: relative;

        @media (max-width: 768px) {
          width: 240px;
          height: 300px;
        }

        .img {
          border-radius: 60px;
          width: 100%;
          height: 100%;
          background-size: cover;
          background-position: center center;
          background-repeat: no-repeat;
          margin: 0;
          position: relative;
          display: flex;
          align-items: center;
          justify-content: center;

          @media (max-width: 768px) {
            width: 100%;
            height: 100%;
          }

          @media (max-width: 1200px) {
          }

          .team {
            position: absolute;
            color: #fff;
            background: #00b2a9;
            width: 70px;
            height: 70px;
            display: flex;
            align-items: center;
            justify-content: center;
            border-radius: 70px;
            bottom: -30px;
            border: 2px solid #fff;
            font-size: 2em;
            font-weight: bold;

            @media (max-width: 1200px) {
              width: 45px;
              height: 45px;
              font-size: 1em;
              bottom: -20px;
            }
          }

          .points {
            display: flex;
            position: absolute;
            top: 0px;
            left: 0px;
            padding: 15px;
            border-bottom-right-radius: 40px;
            background: radial-gradient(
              rgb(255, 255, 255),
              rgba(255, 255, 255, 0.53)
            );
            opacity: 0.7;

            @media (max-width: 1200px) {
              padding: 10px;
            }

            li {
              margin: 2px;
              cursor: pointer;

              svg {
                color: #ccc;
                font-size: 9pt;

                @media (max-width: 1200px) {
                  font-size: 5pt;
                }
              }

              &:hover,
              &.active {
                svg {
                  color: #ffba00;
                }
              }
            }

            &:hover {
              li {
                svg {
                  color: #ccc;

                  &:hover {
                    color: #ffba00;
                  }
                }
              }
            }
          }
        }
      }

      .slick-cloned:last-child {
        display: none;
      }

      .slick-current > div > div {
        opacity: 1 !important;
        width: 442px;
        height: 565px;
      }

      .slick-current + .slick-active {
        width: 360px !important;
        margin-top: 1%;
        @media (max-width: 768px) {
          margin-top: 0;
        }

        div > div {
          @media (max-width: 768px) {
            height: 270px;
          }
          width: 336px;
          height: 429px;
          opacity: 0.7;
        }
      }

      .slick-active + div {
        margin-top: 2%;

        @media (max-width: 768px) {
          margin-top: 0;
        }

        > div > div {
          width: 242px;
          height: 311px;
          opacity: 0.4;
        }
      }
    }
  }
}