.categories {
  padding-top: 50px;
  padding-bottom: 50px;
  position: relative;
  z-index: 5;

  ul {
    width: 100%;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;

    @media (max-width: 1200px) {
      height: auto;
      justify-content: flex-start;
      margin-top: 30px;
      flex-wrap: nowrap;
    }

    li {
      display: flex;
      height: 120px;
      padding: 15px;

      @media (max-width: 1200px) {
        height: auto;
        padding: 7px;
        margin-bottom: 15px;
        border-radius: 15px;
      }

      a {
        display: flex;
        height: 120px;
        justify-content: center;
        align-items: center;
        flex: 1;
        cursor: pointer;
        flex-wrap: wrap;
        width: 100%;

        @media (max-width: 1200px) {
          height: auto;
          padding: 5px;
          border-radius: 30px;
          border: 2px solid var(--blue);
        }

        div {
          width: 70%;
          display: flex;
          align-items: center;
          justify-content: center;

          @media (max-width: 1200px) {
            width: 75%;
          }
        }

        span {
          display: none;
          width: 100%;
          text-align: center;
          color: var(--blue);
          font-size: 10pt;
          font-weight: 800;
          margin-top: 7px;
        }

        svg {
          transform: scale(1);
          transition: all cubic-bezier(0, 0, 1, -0.43) 0.7s;
        }
      }

      &:hover {
        a {
          span {
            display: block;
          }

          svg {
            transform: scale(1.2);
            path,
            circle,
            line,
            rect,
            ellipse {
              stroke: var(--blue);
            }
          }

          @media (max-width: 1200px) {
            span {
              display: none;
            }
          }
        }
      }
    }
  }
}
