.as-nav-for {
  width: 100%;

  &__main,
  &__thump {
    img {
      width: 100%;
      height: 100%;
      object-position: center;
      object-fit: cover;
    }
  }

  &__main {
    .slick-slide > div {
      margin: 0 5px;
    }

    &__item {
      height: 410px;
    }
  }

  &__thump {
    margin: 10px 5px;

    .slick-active.slick-current {
      .as-nav-for__thump__item {
        border: 3px solid #00b2a9;
      }
    }

    &__item {
      height: 100px;
    }
  }
}
