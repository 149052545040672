.typography {
  width: 100%;
  height: auto;
  font-family: 'Poppins', sans-serif;

  &.label-text {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;

    .label {
      flex: 0 0 auto;
      font-weight: bold;
      margin-right: 5px;
    }

    .text {
      flex: 1 1 auto;
    }
  }

  &.text {
    font-size: 14pt;
    padding-top: 15px;
    padding-bottom: 15px;
  }

  &.title {
    position: relative;
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    font-family: 'Poppins', sans-serif;
    letter-spacing: 3px;
    text-align: center;

    &.text-left {
      align-items: flex-start;
    }

    &.text-right {
      align-items: flex-end;
    }

    &.line:after {
      content: '';
      width: 128px;
      background: #00b2a9;
      height: 2px;
      margin-top: 16px;
      display: block;
      left: 50%;
      transform: translateX(-50%);
      position: relative;
    }
  }

  &.bold {
    font-weight: 700;
  }

  &.bolder {
    font-weight: 900;
  }

  &.lighter {
    font-weight: 300;
  }

  &.normal {
    font-weight: normal;
  }
}
