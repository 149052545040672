.nav-tabs .nav-link {
  font-weight: 500;
  font-size: 16px;
  letter-spacing: 0.03em;
  margin-bottom: -1px;
  color: var(--gray1);
}

  #pt-point-page  #pt-point-page .top-promotion, #pt-point-page .pt, #pt-point-page  {
@media (max-width: 768px){

    padding-bottom: 0;
    padding-top: 5px;
     margin-top: -10%;
}}