.blog {
  padding-left: 50px;
  padding-right: 50px;
  margin-bottom: 100px;
  margin-top: 10px;
  padding-top: 0;
  @media (max-width: 768px) {
    margin-top: 0;
    margin:0 -30px;
  }
  &__content {
    margin-top: 70px;
    margin-bottom: 76px;
    @media (max-width: 768px) {
      margin-top: 15px;
  
    }
  }

  .typography.title.line:after {
    width: 80px;
  }
}
