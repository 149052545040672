.btn {
  border: none;

  &:focus,
  &:active,
  &.active {
    border: none;
    box-shadow: none;
    background: inherit;
    color: inherit;
  }
}

.btn.btn-line.blue {
  background: transparent;
  color: #00b2a9;

  &:after {
    background: #00b2a9;
  }
}

.btn.btn-line.gray {
  background: transparent;
  color: #909090;

  &:after {
    background: #909090;
  }
}

.btn.btn-line.dark {
  background: transparent;
  color: #181818;

  &:after {
    background: #181818;
  }
}

.btn.blue {
  background: #00b2a9;
  color: #fff;

  &:hover {
    color: #fff;
  }
}

.btn.gray {
  background: #909090;
  color: #fff;

  &:hover {
    color: #fff;
  }
}

.btn.dark {
  background: #181818;
  color: #fff;

  &:hover {
    color: #fff;
  }
}

.icon-button {
  color: #181818;
  font-weight: 300;

  .icon {
    margin-right: 10px;
    color: #181818;
    font-size: 10pt;
  }

  &:hover {
    color: #181818;
  }
}

.btn.icon-button {
  .icon {
    margin-right: 10px;
    color: #fff;
    font-size: 10pt;
  }

  span {
    &:before {
      content: '|';
      display: inline-block;
      margin-right: 10px;
      font-weight: lighter;
    }
  }
}

.btn.perspective-button.blue {
  background: transparent;
  color: #fff;

  &:after {
    background: #00b2a9;
  }
}

.btn.perspective-button.gray {
  background: transparent;
  color: #fff;

  &:after {
    background: #909090;
  }
}

.btn.perspective-button.dark {
  background: transparent;
  color: #fff;

  &:after {
    background: #181818;
  }
}

.btn:not(:disabled):not(.disabled).active,
.btn:not(:disabled):not(.disabled):active,
.show > .btn.dropdown-toggle {
  color: initial;
  background-color: initial;
  border-color: initial;
}

.btn:not(:disabled):not(.disabled).active:focus,
.btn:not(:disabled):not(.disabled):active:focus,
.show > .btn.dropdown-toggle:focus {
  box-shadow: none;
}

.button-close-icon {
  background: white;
  border-radius: 25px;
  width: 30px;
  height: 25px;
  color: var(--blue);
  margin-left: 10px;
  display: inline-block;
}
