.fluid-banner {
    min-height: 75px;
    width: 100%;
    background-size: cover;
    padding-top: 30px;
    padding-bottom: 30px;
    position: relative;

    &:before {
        content: "";
        width: 100%;
        height: 100%;
        position: absolute;
        z-index: 0;
        background: rgba(0,0,0,.5);
        top: 0;
        left: 0;
    }

    .typography {
        position: relative;
        z-index: 10;
    }
}