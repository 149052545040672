.header {
  z-index: 10001;
  .logo {
    height: 130px;
    background: #1a1818;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    flex: 1 1;
    max-width: 325px;

    @media (max-width: 1200px) {
      height: 90px;
      max-width: 215px;
      align-items: center;
      justify-content: center;

    }

    @media (max-width: 768px) {
      max-width: 115px;

    }

    img {
      max-height: 130px;

      @media (max-width: 1200px) {
        max-height: 75px;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        height: 90px;
      }
    }
  }
  .nav-container {
    @media (max-width: 1200px) {
      display: flex;
      flex-direction: column-reverse;
    }
  }
  .nav-menu {
    margin: 0 15px 0 0px;
    padding: 0 15px;

    @media (max-width: 1200px) {
      height: 100vh;
      width: 55%;
      top: 0;
      background: #ebebeb;
      margin: 0;
      left: -70%;
      position: fixed;
      padding: 5%;
      overflow: auto;
      transition: left 0.5s ease;

      &.open {
        left: 0;
      }
    }

    @media (max-width: 768px) {
      width: 70%;
    }

    .bar {
      padding: 0;

      .menubar-search-wrapper {
        position: relative;
        svg {
          position: absolute;
          left: 40px;
          right: 5px;
          top: 20px;
          color: white;
        }
      }

      .menubar-search-input {
        background-color: #00b2a9;
        border: none;
        border-radius: 5px;
        padding-top: 18px;
        padding-bottom: 18px;
        padding-left: 63px;
        font-size: 1rem;
        color: white;

        &::placeholder {
          /* Chrome, Firefox, Opera, Safari 10.1+ */
          color: white;
          opacity: 1; /* Firefox */
        }
      }
      .nav-element {
        width: 100%;
        justify-content: space-between;
        margin: 0;

        @media (max-width: 1200px) {
          padding-top: 15px;
          padding-bottom: 15px;
          align-content: flex-start;
          flex-direction: column-reverse;
        }

        .bar-item {
          display: flex;
          align-items: center;

          @media (max-width: 1200px) {
            width: 100%;
            height: auto;
          }

          ul {
            display: flex;
            flex-direction: row;
            margin-left: -15px;
            margin-right: -15px;

            @media (max-width: 1200px) {
              width: 100%;
              flex-direction: column;
            }

            @import '../header-login';

            li {
              margin: 0 15px;
              font-size: 10pt;
              display: flex;
              font-size: 0.9rem;
              height: 40px;
              transition: all 0.5s cubic-bezier(1, 0, 0, 0.99);
              height: auto;

              &.dropdown {
                display: flex;
                background: #cacaca;
                color: #fff;
                position: relative;

                &::before {
                  content: '+';
                  display: inline;
                  width: auto;
                  height: auto;
                  position: absolute;
                  right: 15px;
                }

                ul {
                  position: absolute;
                  background: #ddd;
                  z-index: 1000;
                  left: 15px;
                  top: 100%;
                  transition: all 0.5s cubic-bezier(1, 0, 0, 0.99);
                  transform: translateX(-100%);
                  opacity: 0;

                  &.open {
                    transform: translateX(0);
                    opacity: 1;
                  }

                  li {
                    margin: 0;
                    padding: 0;
                    background: #cacaca;
                    border-bottom: 1px solid #bfbfbf;

                    a {
                      width: 100%;
                      padding: 15px;
                      font-size: 9pt;
                      color: #fff;
                    }
                  }
                }
              }

              @media (max-width: 1200px) {
                width: 100%;
                margin-top: 15px;
                border: none;
                padding: 15px;
                background: #e5e5e5;
              }

              @media (max-width: 768px) {
                width: 100%;
                margin-top: 5px;
                border: none;
                padding: 10px;
                background: #e5e5e5;
                font-size: 10pt;
              }

              &.line {
                align-items: center;

                @media (max-width: 1200px) {
                  display: none;
                }

                span {
                  width: 1px;
                  height: 55%;
                  background: #cbcbcb;
                }
              }

              .btn {
                height: 55px;
                padding-left: 35px;
                padding-right: 35px;

                @media (max-width: 1200px) {
                  width: 100%;
                }

                @media (max-width: 768px) {
                  border-radius: 0;
                  height: 35px;
                }
              }

              a {
                color: #181818;
                display: flex;
                align-items: center;
                font-size: 13pt;
                font-weight: 600;

                @media (max-width: 1200px) {
                  width: 100%;
                }
              }
            }
          }

          .lang {
            display: flex;
            align-items: center;
            border-left: 1px solid #cbcbcb;
            border-right: 1px solid #cbcbcb;
            padding: 0 10px;

            @media (max-width: 1200px) {
              position: absolute;
              top: calc(-5% - 15px);
              background: transparent;
              width: 100%;
              padding: 15px 0;
              border: none;
              display: flex;
              align-items: center;
              margin: 0;
              border: none;
              justify-content: space-between;
            }

            a {
              color: #181818;
              display: flex;
              align-items: center;
              font-size: 12pt;
              font-weight: 300;
              margin-left: 5px;
              margin-right: 5px;

              @media (max-width: 1200px) {
                display: flex;
                align-items: center;
                font-weight: 300;
                margin-left: 5px;
                margin-right: 5px;
                width: 45%;
                background: #181818;
                color: #fff;
                padding: 5px;
                text-align: center;
                justify-content: center;
                margin: 0;
              }

              @media (max-width: 768px) {
                font-size: 9pt;
              }
            }
          }
        }
      }

      &.widget-bar {
        border-bottom: 1px solid #cbcbcb;
        padding-bottom: 5px;
        padding-top: 10px;

        @media (max-width: 768px) {
          border-bottom: 1px solid #cbcbcb;
          padding-bottom: 5px;
          padding-top: 10px;
          margin-top: 15px;
          margin-bottom: -15px;
        }

        .bar-item {
          ul {
            li {
              a {
                font-size: 0.9rem;
                font-weight: 400;
              }
            }
          }
        }
      }
    }
  }
}
