.blog-list {
  padding-top: 100px;
  margin-bottom: 200px;
  // &__top-banner {
  //   height: 314px;
  //   background: no-repeat url('../../assets/info/banner/info-img.jpg');
  // }

  &__title {
    margin-top: 86px;
    margin-bottom: 116px;
    max-width: max-content;
    margin-left: 300px;
  }

  &__content {
    margin-bottom: 110px;
  }
}
