.header-login {
  display: flex;

  li {
    height: 85px;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    position: relative;

    &:hover {
      .drop-down {
        transform: scale(1) rotate(0deg);
      }
    }

    .drop-down {
      width: 255px;
      position: absolute;
      right: 0;
      height: auto;
      flex-wrap: wrap;
      top: 85px;
      background: #fff;
      border-radius: 10px;
      padding: 5px;
      box-shadow: 6px 4px 6px -5px rgba(0, 0, 0, 0.51);
      transition: all 0.1s cubic-bezier(1, 0, 0, 0.99);
      transform: scale(0) rotate(0deg);

      .drop-li {
        & > ul > li > .ul-drop-down {
          top: -40px;
          right: 0;
          transform: scale(0) translateX(-200%);
          transition: all 0.5s cubic-bezier(1, 0, 0, 0.99);
        }
        &:hover {
          & > ul > li > .ul-drop-down {
            transform: scale(1) translateX(0);
          }
        }
      }

      li {
        width: 100%;
        display: flex;
        justify-content: flex-start;
        align-items: flex-start;
        flex: 1 1 100%;
        height: auto;
        padding: 0;
        margin: 0;
        border-bottom: 1px solid #ddd;

        a {
          font-size: 10pt;
          margin: 5px;
          font-weight: normal;
          display: flex;
          padding: 7px;
          width: 100%;
          color: #6b6b6b;
        }

        span.item-icon {
          margin-right: 15px;
          opacity: 0.7;
        }

        &:hover,
        &.active {
          a {
            color: var(--blue);
          }
          span.item-icon {
            transform: scale(1.2);
            opacity: 1;
          }
        }
      }
    }

    span {
      display: flex;
      align-items: center;
      justify-self: center;
      font-size: 10pt;

      &.icon {
        border-radius: 100%;
        width: 40px;
        height: 40px;
        padding: 5px;
        overflow: hidden;

        img {
          width: 40px;
          height: 40px;
        }

        * {
          width: 100%;
          display: flex;
          align-items: center;
          justify-content: center;
        }

        svg {
          width: 25px;
          height: 25px;
        }
      }

      &.title {
        margin-top: 7px;
      }
    }
  }
}
